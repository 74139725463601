import * as firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/firestore';

class FirebaseModelBackend {
    constructor() { }
    createNewDocumentBycollectionPath = (collectionPath: string, data: any) => {
        return new Promise((resolve, reject) => {
            const docRef = firebase.firestore().collection(collectionPath);
            docRef.add(data).then((doc) => {
                resolve(doc);
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    createDocumentWithSetIDAndcollectionPath = (ID: string, collectionPath: string, data: any) => {
        return new Promise((resolve, reject) => {
            const docRef = firebase.firestore().collection(collectionPath);
            docRef.doc(ID).set(data);
            resolve(ID)
                , (error) => {
                    reject(this._handleError(error));
                }
        })
    }
    editDocumentByDocPath = (docPath: string, data: any) => {
        return new Promise((resolve, reject) => {
            const docRef = firebase.firestore().doc(docPath);
            docRef.update(data).then((doc) => {
                resolve(doc);
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    editDocumentBydeletingMap = (docPath: string, map: string) => {
        return new Promise((resolve, reject) => {
            const docRef = firebase.firestore().doc(docPath);
            docRef.update({ [map]: firebase.firestore.FieldValue.delete() }).then((doc) =>
                resolve(doc)
            ), (error) => {
                reject(this._handleError(error));
            }
        })
    }
    deleteDocumentByDocPath = (docPath: string) => {
        return new Promise((resolve, reject) => {
            const docRef = firebase.firestore().doc(docPath);
            docRef.delete().then(
                function (doc) {
                    resolve(doc);
                }
            ), (error) => {
                reject(this._handleError(error));
            }
        })
    }
    getDocumentByDocPath = (docPath: string) => {
        return new Promise((resolve, reject) => {
            const docRef = firebase.firestore().doc(docPath);
            docRef.get().then((doc: any) => {
                const docData = { id: doc.id, ...doc.data() };
                resolve(docData);
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getACollictionDocsWithLimitFirst = (collectionPath: string, orderBy: string,orderType:string, limit: number) => {
        return new Promise((resolve, reject) => {
            const first = firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc").limit(limit);
            var documents = [];
            first.get().then((documentSnapshots: any) => {
                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                //console.log("lastVisible:",lastVisible);
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                resolve({documents,"path":collectionPath,lastVisible});
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getACollictionDocsWithLimitNext = (collectionPath:any, orderBy: string,orderType:string, limit: number,lastVisible:any) => {
        return new Promise((resolve, reject) => {
            const next =firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc").limit(limit).startAt(lastVisible);
            var documents = [];
            next.get().then((documentSnapshots: any) => {
                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                //console.log("lastVisible:",lastVisible)
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                resolve({documents,lastVisible});
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getACollictionDocsWithLimitPrev = (collectionPath:any, orderBy: string,orderType:string, limit: number,lastVisible:any) => {
        return new Promise((resolve, reject) => {
            const next =firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc").limit(limit).startAt(lastVisible);
            var documents = [];
            next.get().then((documentSnapshots: any) => {
                const FirstVisible = documentSnapshots.docs[0];
                //console.log("FirstVisible:",FirstVisible)
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                resolve({documents,FirstVisible});
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getAllCollectionDoucmentsByCollectionPath = (collectionPath: string) => {
        return new Promise((resolve, reject) => {
            const docRef = firebase.firestore().collection(collectionPath);
            var documents = [];
            docRef.get().then((querySnapshot: any) => {
                querySnapshot.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                resolve(documents);
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }
    getDocBySearchAuniqeProperty(collectionPath: string, prop: string, value: string) {
        return new Promise((resolve, reject) => {
            const collection = firebase.firestore().collection(collectionPath);
            collection.where(prop, "==", value).get().then((querySnapshot) => {
                if(!querySnapshot.empty){
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        //console.log(doc.id, " => ", doc.data());
                        resolve({ id: doc.id, ...doc.data() });
                    });
                } 
                else{
                    //console.log("empty snap")
                    resolve(null)
                }    
            }, (error) => {
                reject(this._handleError(error));
            })
        })
    }

    async checkDocsExistanceInCollectionWithUniqueProperty(collectionPath: string, property: string, value: string) {
        const collection = firebase.firestore().collection(collectionPath);
        const querySnapshot = await collection.where(property, "==", value).limit(1).get();
        if(!querySnapshot.empty){
            return true;
        } 
        else{
            return false
        }
    }

    async checkDocsExistanceInCollectionWithCoupleProperties(collectionPath,properties:any){
        const collection = firebase.firestore().collection(collectionPath);
        const querySnapshot = await collection.where(properties[0].name, "==", properties[0].value).
        where(properties[1].name, "==", properties[1].value).limit(1).get();
        if(!querySnapshot.empty){
            return true;
        } 
        else{
            return false
        }
    }

    async getNumOcceranceDocsInCollectionWithCoupleProperties(collectionPath,properties:any){
        const collection = firebase.firestore().collection(collectionPath);
        const querySnapshot = await collection.where(properties[0].name, "==", properties[0].value).
        where(properties[1].name, "==", properties[1].value).get();
        if(!querySnapshot.empty){
            return querySnapshot.docs.length;
        } 
        else{
            return 0;
        }
    }

    makeCollectionGroupQuery(collection:string,searchKey:string,value:string){
        return new Promise((resolve, reject) => {
            const collectionDocs = firebase.firestore().collectionGroup(collection).where(searchKey, '==', value).limit(1);
            collectionDocs.get().then((querySnapshot) => {
                querySnapshot.forEach((doc)=>{
                    resolve({id:doc.id, data:doc.data()});
                })
                resolve(null);
            }, (error) => {
                reject(this._handleError(error));
            });
        })
    }

    makeCollectionGroupArrayContainQuery(collection:string,searchKey:string,value:string){
        return new Promise((resolve, reject) => {
            const collectionDocs = firebase.firestore().collectionGroup(collection).where(searchKey, 'array-contains', value).limit(1);
            collectionDocs.get().then((querySnapshot) => {
                querySnapshot.forEach((doc)=>{
                    resolve({id:doc.id, data:doc.data()});
                })
                resolve(null);
            }, (error) => {
                reject(this._handleError(error));
            });
        })
    }

    getDocumentWithListener(docPath:string,cb){
        const docRef = firebase.firestore().doc(docPath);
        docRef.onSnapshot((doc:any)=>{
            if(doc)
            {
                const docData = { id: doc.id, ...doc.data() };
                //console.log("doc Updated== ",doc.data());
                cb(docData,false);
            }else{
                cb(null,true);
            }
        })
    }
    getCollicationUpdatesWithListener(collectionPath:string,orderBy,orderType,cb){
        const q = firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc")
        q.onSnapshot((querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb(docs,false);
            }    
        })
    }
    getCollicationWithListenerFirst(collectionPath:string,orderBy: string,orderType:string, limit: number,cb){
        const q = firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc").limit(limit);
        q.onSnapshot((querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,"path":collectionPath,lastVisible},false);
            }    
        })
    }
    getCollicationWithListenerNext(collectionPath:string,orderBy: string,orderType:string, limit: number,LastVisible:any,cb){
        const q = firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc").limit(limit).startAfter(LastVisible);
        q.onSnapshot((querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,lastVisible},false);
            }    
        })
    }
    getCollicationWithListenerNextStartAt(collectionPath:string,orderBy: string,orderType:string, limit: number,LastVisible:any,cb){
        const q = firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc").limit(limit).startAt(LastVisible);
        q.onSnapshot((querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,lastVisible},false);
            }    
        })
    }
    getCollicationWithListenerPrev(collectionPath:string,orderBy: string,orderType:string, limit: number,LastVisible:any,cb){
        const q = firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc").limit(limit).startAfter(LastVisible);
        q.onSnapshot((querySnapshot:any)=>{
            if(querySnapshot){
                const docs=[];
                const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                querySnapshot.forEach((doc:any) => {
                    const document = { id: doc.id, ...doc.data() }
                    docs.push(document);
                });
                cb({documents:docs,lastVisible},false);
            }    
        })
    }


    getCollicationWithOrder(collectionPath:string,orderBy: string,orderType:string,cb){
        const ordered = firebase.firestore().collection(collectionPath).orderBy(orderBy, orderType==='desc' ? "desc" : "asc")
        ordered.onSnapshot((documentSnapshots: any) => {
            if(documentSnapshots){
                let documents = [];
                documentSnapshots.forEach(function (doc) {
                    const document = { id: doc.id, ...doc.data() }
                    documents.push(document);
                });
                cb(documents,false);
            } 
        });
    }
    

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!localStorage.getItem('authUser')) {
            return null;
        }
        return JSON.parse(localStorage.getItem('authUser'));
    }

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error) {
        // tslint:disable-next-line: prefer-const
        var errorMessage = error.message;
        return errorMessage;
    }
}

// tslint:disable-next-line: variable-name
let _fireBaseBackend = new FirebaseModelBackend();

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export { getFirebaseBackend };
