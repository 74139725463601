const PROJECT_COLLECTION_PATH=`projects/`;
const USER_COLLECTION_PATH=`companies/`;
const COMPANY_COLLECTION_PATH=`users/`;

const TRACKER_COLLECTION_PATH=`/trackers/`;
const MODEL_COLLECTION_PATH=`/models/`;
const EXPRIMENT_COLLECTION_PATH=`/model_experiments/`;
const INTEGRATION_COLLECTION_PATH=`/integrations/`;

const SHOPIFY_WIDGET_COLLECTION_PATH=`/recommendation_widgets/`;
const TRAINING_JOB_COLLECTION_PATH='/training_jobs/';
const DEPLOYMENT_JOB_COLLECTION_PATH='/deployment_jobs/';

function get_project_document_path(DocID:string){
    return `${PROJECT_COLLECTION_PATH}${DocID}`;
}

function get_user_document_path(DocID:string){
    return `${USER_COLLECTION_PATH}${DocID}`;
}

function get_company_document_path(DocID:string){
    return `${COMPANY_COLLECTION_PATH}${DocID}`;
}

function get_tracker_collection_path(pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${TRACKER_COLLECTION_PATH}`;
}

function get_tracker_document_path(DocID:string,pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${TRACKER_COLLECTION_PATH}${DocID}`;
}

function get_model_collection_path(pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${MODEL_COLLECTION_PATH}`;
}

function get_model_document_path(DocID:string,pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${MODEL_COLLECTION_PATH}${DocID}`;
}

function get_expriment_collection_path(pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${EXPRIMENT_COLLECTION_PATH}`;
}

function get_expriment_document_path(DocID:string,pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${EXPRIMENT_COLLECTION_PATH}${DocID}`;
}

function get_training_job_collection_path(pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${MODEL_COLLECTION_PATH}${pathPre.kainic_mid}${TRAINING_JOB_COLLECTION_PATH}`;
}

function get_training_job_document_path(DocID:string,pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${MODEL_COLLECTION_PATH}${pathPre.kainic_mid}${TRAINING_JOB_COLLECTION_PATH}${DocID}`;
}

function get_deployment_job_collection_path(pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${MODEL_COLLECTION_PATH}${pathPre.kainic_mid}${DEPLOYMENT_JOB_COLLECTION_PATH}`;
}

function get_deployment_job_document_path(DocID:string,pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${MODEL_COLLECTION_PATH}${pathPre.kainic_mid}${DEPLOYMENT_JOB_COLLECTION_PATH}${DocID}`;
}
function get_widget_document_path(DocID:string,pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${INTEGRATION_COLLECTION_PATH}${pathPre.kainic_iid}${SHOPIFY_WIDGET_COLLECTION_PATH}${DocID}`;
}
function get_widget_collection_path(pathPre:any){
    return `${PROJECT_COLLECTION_PATH}${pathPre.kainic_pid}${INTEGRATION_COLLECTION_PATH}${pathPre.kainic_iid}${SHOPIFY_WIDGET_COLLECTION_PATH}`;
}

export function get_Firestore_path(dataType:string,pathPre?:any,DocID?:string){
    if(dataType==='project'){
        if(DocID){return get_project_document_path(DocID)}else{return PROJECT_COLLECTION_PATH};
    }
    else if(dataType==='company'){
        if(DocID){return get_company_document_path(DocID)}else{return COMPANY_COLLECTION_PATH};
    }
    else if(dataType==='user'){
        if(DocID){return get_user_document_path(DocID)}else{return USER_COLLECTION_PATH};
    }
    else if(dataType==='tracker'&&pathPre){
        if(DocID){return get_tracker_document_path(DocID,pathPre)}else{return get_tracker_collection_path(pathPre)};
    }
    else if(dataType==='model' &&pathPre){
        if(DocID){return get_model_document_path(DocID,pathPre)}else{return get_model_collection_path(pathPre)};
    }
    else if(dataType==='experiment' &&pathPre){
        if(DocID){return get_expriment_document_path(DocID,pathPre)}else{return get_expriment_collection_path(pathPre)};
    }
    else if(dataType==='training' &&pathPre){
        if(DocID){return get_training_job_document_path(DocID,pathPre)}else{return get_training_job_collection_path(pathPre)};
    }
    else if(dataType==='deployment' &&pathPre){
        if(DocID){return get_deployment_job_document_path(DocID,pathPre)}else{return get_deployment_job_collection_path(pathPre)};
    }
    else if(dataType==='widget' &&pathPre){
        if(DocID){return get_widget_document_path(DocID,pathPre)}else{return get_widget_collection_path(pathPre)}
    }
    
}








