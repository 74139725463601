import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../firebaseBackend/firestoreUtils';
import { AuthorityService } from './authority.service';
import { LogsService } from './logs.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private moduleName = 'projects';

  constructor(private AuthorityService: AuthorityService,private logsService: LogsService,private userService:UserService) { }
  /**
    * create project
    */
  createProject(project: any) {
    var AuthUser = getFirebaseBackend().getAuthenticatedUser();
    const docPathUser = `users/${AuthUser.uid}`;
    const collectionPathProject = `projects`;
    return getFirebaseBackend().createNewDocumentBycollectionPath(collectionPathProject, project).then((Project: any) => {
      var projectID = Project.id;
      getFirebaseBackend().getDocumentByDocPath(docPathUser).then((user: any) => {
        var userProjectRole;
        userProjectRole={
          projects:{
            [projectID]:{
              name:project.name,
              role:"owner"
            },
            ...user.projects
          } 
        }
        getFirebaseBackend().editDocumentByDocPath(docPathUser, userProjectRole);
      })
      //console.log("gyfugfeyiuiouowo", Project.id);
      return Project.id;
    })
  }
  /**
   * get all user project
   */
  getUserProjects() {
    var AuthUser = getFirebaseBackend().getAuthenticatedUser();
    const docPathUser = `users/${AuthUser.uid}`;
    return getFirebaseBackend().getDocumentByDocPath(docPathUser).then((user: any) => {
      const userProjects = user.projects;
      const orderdProjects = [];
      let index: number = 0;
      for (const pid in user.projects) {
        index += 1;
        const data = {
          image: 'assets/images/companies/img-' + (index%6 !==0 ? index%6 : 6 ) + '.png',
          name: userProjects[pid].name,
          id: pid
        };
        orderdProjects.push(data);
      }
      orderdProjects.sort(function(a:any,b:any){
        if(a.name > b.name){
          return 1;
        }
        if(a.name < b.name){
          return -1;
        }
        return 0;
      })
      return orderdProjects;
    })
  }
  getUserProjectsWithUpdates(cb){
    let AuthUser = getFirebaseBackend().getAuthenticatedUser();
    const docPathUser = `users/${AuthUser.uid}`;
    getFirebaseBackend().getDocumentWithListener(docPathUser,(user: any,err) => {
      const userProjects = user.projects;
      const orderdProjects = [];
      let index: number = 0;
      for (const pid in user.projects) {
        index += 1;
        const data = {
          image: 'assets/images/companies/img-' + (index%6 !==0 ? index%6 : 6 ) + '.png',
          name: userProjects[pid].name,
          id: pid
        };
        orderdProjects.push(data);
      }
      orderdProjects.sort(function(a:any,b:any){
        if(a.name > b.name){
          return 1;
        }
        if(a.name < b.name){
          return -1;
        }
        return 0;
      })
      cb(orderdProjects);
    })
  }
  /**
    * get a project data
    */
  getProjectData(pid: string) {
    const docPath = `projects/${pid}`;
    return getFirebaseBackend().getDocumentByDocPath(docPath).then((project: any) => {
      return project;
    })
  }
  getProjectDataWithUpdate(pid: string,cb) {
    const docPath = `projects/${pid}`;
    return getFirebaseBackend().getDocumentWithListener(docPath,(res) => {
      cb(res)
    })
  }
  getProjectMembers(pid: string) {
    var usersRecords = []
    return this.getProjectData(pid).then((project: any) => {
      project.users.forEach(uid => {
        getFirebaseBackend().getDocumentByDocPath(`users/${uid}`).then((user:any)=>{
          var userRecord={
            uid:uid,
            name:user.firstName+" "+user.lastName,
            email:user.email,
            role:user.projects[pid].role
          }
          usersRecords.push(userRecord);
        }) 
      });
      // console.log("usersRole", usersRecords);
      return usersRecords;
    })
  }
  ////////////////////////////
  getUserByEmail(email:string){
    return getFirebaseBackend().getDocBySearchAuniqeProperty("users","email",email).then((res)=>{
       return res
    })
  }
  ///////////////////////////
  addMemberToProject(uid:string,pid:string,role:string){
    return this.getProjectData(pid).then((project: any) => {
      var users=project.users;
      users.push(uid);
      const docPathProject = `projects/${pid}`;
      getFirebaseBackend().editDocumentByDocPath(docPathProject,{users:users}).then(()=>{
        this.userService.getUserDataWithID(uid).then((userData:any)=>{
          this.logsService.sendLogRequest(pid,{
            log_generated_ts: new Date().getTime(),
            module: this.moduleName,
            action: "admin_added",
            data: JSON.stringify({
              admin_email: userData.email
            })
          })
        })
      })
      this.addProjectToMember(uid,pid,project.name,role);
      return true;
    })
  }
  addProjectToMember(uid:string,pid:string,Pname:string,role:string){
    const docPathUser = `users/${uid}`;
    getFirebaseBackend().getDocumentByDocPath(docPathUser).then((user: any) => {
      // console.log(user.projects)
        var userProjectRole;
        userProjectRole={
          projects:{
            [pid]:{
              name:Pname,
              role:role
            },
            ...user.projects
          } 
        }
        getFirebaseBackend().editDocumentByDocPath(docPathUser, userProjectRole);
    })
  }
  checkEmail(email){
    return getFirebaseBackend().getDocBySearchAuniqeProperty("users","email",email).then((doc)=>{
      // console.log(email)
      if(doc){
        return doc;
      }
    })
  }
  deleteMemberFromProject(uid: string, pid: string) {
    return this.getProjectData(pid).then((project: any) => {
      const docPathProject = `projects/${pid}`;
      var users: any = project.users;
      users.pop(uid);
      var AuthUserID = getFirebaseBackend().getAuthenticatedUser().uid;
      if (AuthUserID !== uid) {
        this.AuthorityService.getUserRole(uid, pid).then((role) => {
          if (role !== "owner") {
            this.deletProjectFromUser(uid, pid);
            getFirebaseBackend().editDocumentByDocPath(docPathProject, { users: users }).then(()=>{
              this.userService.getUserDataWithID(uid).then((userData:any)=>{
                this.logsService.sendLogRequest(pid,{
                  log_generated_ts: new Date().getTime(),
                  module: this.moduleName,
                  action: "admin_removed",
                  data: JSON.stringify({
                    admin_email: userData.email
                  })
                })
              })
            })
          }
        })
      }
      // console.log("users after delete:", users);
      return true;
    })
  }
  deletProjectFromUser(uid, pid) {
    const docPathUser = `users/${uid}`;
    getFirebaseBackend().getDocumentByDocPath(docPathUser).then((user: any) => {
      var projects: any = user.projects;
      delete projects[pid];
      // console.log("after pop a project", projects);
      getFirebaseBackend().editDocumentByDocPath(docPathUser, { projects: projects })
    })
  }
  changeMemberRole(uid:string,pid:string,newRole:string){
    this.AuthorityService.changeUserRole(uid,pid,newRole);
  }
  TransferProjectOwnership(oldOwnerID: string, newOwnerID: string, pid: string) {
    return this.AuthorityService.changeUserRole(newOwnerID, pid, "owner").then(()=>{
      this.AuthorityService.changeUserRole(oldOwnerID, pid, "admin").then(()=>{
        this.userService.getUserDataWithID(oldOwnerID).then((oldUserData)=>{
          this.userService.getUserDataWithID(newOwnerID).then((newUserData:any)=>{
            this.logsService.sendLogRequest(pid,{
              log_generated_ts: new Date().getTime(),
              module: this.moduleName,
              action: "ownership_transferred",
              data: JSON.stringify({
                new_owner_email: newUserData.email,
                old_owner_email: oldUserData.email
              })
            })
          })
        })
        return true;
      })
    })
  }

  editProjectProductsSyncDict(pid:string,productsSync:any){
    const docPathProject = `projects/${pid}`;
    return getFirebaseBackend().editDocumentByDocPath(docPathProject,{'products_sync' : productsSync}).then((res:any)=>{
      return res;
    })
  }

  getProjectWithListener(pid,cb){
    const docPathProject = `projects/${pid}`;
    return getFirebaseBackend().getDocumentWithListener(docPathProject,cb);
  }

  updateProjectMinPredictionStock(pid,min_prediction_stock){
    const docPathProject = `projects/${pid}`;
    return getFirebaseBackend().editDocumentByDocPath(docPathProject,{'models.min_prediction_stock': min_prediction_stock}).then((res:any)=>{
      return res;
    })
  }

  updateProjectMinCreditNotification(pid,min_credit_notification){
    const docPathProject = `projects/${pid}`;
    return getFirebaseBackend().editDocumentByDocPath(docPathProject,{'billing.min_credit_notification': min_credit_notification}).then((res:any)=>{
      return res;
    })
  }
}
